<template>
  <div>
    <header class="header">
      <div class="header-text">付款给{{ payOrderInfo.mchName }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>

      <!-- 手写输入框 -->
      <div  class="input-c">
        <div  class="input-c-div-1">
          <input type="text" v-model="amount" @input="inputFn" placeholder="请输入金额" />
        </div>
        <!-- 数字金额后边的光标 -->
        <!-- <div class="input-c-div" style="background:#07c160"></div> -->
      </div>
    </div>
    <ul class="plus-ul" >
      <!-- 支付板块 -->
      <li
          style="border-radius:10px;"
      >
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="wxImg" alt="" />
          <div class="div-text">
            微信支付
          </div>
        </div>
      </li>
    </ul>
    <!-- 备注板块 ，目前不需要添加备注，隐藏-->
    <!-- <div class="remark-k" :class="payType != 'wx' ? 'margin-top-30' : ''">
      <div class="remark">
        <div class="remark-hui" v-show="remark">{{ remark }}</div>
        <div @click="myDialogStateFn">{{ remark ? "修改" : "添加备注" }}</div>
      </div>
    </div> -->
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <!-- <MyDialog
      v-show="myDialogState"
      @myDialogStateFn="myDialogStateFn"
      :remark="remark"
    >
    </MyDialog> -->

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <!-- <div class="keyboard-plus" v-if="isAllowModifyAmount">
      <Keyboard
        @delTheAmount="delTheAmount"
        @conceal="conceal"
        @enterTheAmount="enterTheAmount"
        @payment="payment"
        :money="money"
        :concealSate="concealSate"
        :typeColor="typeColor[payType]"
      ></Keyboard>
    </div> -->

    <!-- jeepay中，付款的点击事件 由 payment 修改为 pay  -->
    <!-- jeepay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
    <!-- <div class="bnt-pay" v-if="!isAllowModifyAmount"> -->
    <div class="bnt-pay">
      <div
          class="bnt-pay-text"
          style="background-color:#07c160"
          @click="pay"
      >
        付款
      </div>
    </div>
  </div>
</template>

<script>
// import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
// import Keyboard from "./../keyboard/keyboard";  // 手写键盘
import {unifiedOrder} from "@/api/api";

export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // components: { MyDialog, Keyboard },
  data: function (){
    return {
      merchantName: 'jeepay',  // 付款的商户默认
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      amount: 0,  // 支付金额默认
      resData : {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
    }
  },

  mounted() {
    this.setPayOrderInfo(); //获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo(){
      if (this.$route.params) {
        this.payOrderInfo = this.$route.params;
      }
    },

    inputFn() {

    },
    isPositiveNumber(value) {
      const number = parseFloat(value);
      return !isNaN(number) && isFinite(number) && number > 0;
    },

    // 支付事件
    pay: function () {
      if (this.amount <= 0) {
        alert('请输入正确金额');
        return;
      }
      unifiedOrder({
        "amount": parseInt((this.amount * 100) + ""),
        "extParam": "",
        "mchOrderNo": "M" + (new Date().getTime()),
        "subject": "商品标题",
        "wayCode": "QR_CASHIER",
        "sign": "fsdafae311241414314324324",
        "reqTime": Math.floor(new Date().getTime() / 1000),
        "body": "商品描述",
        "version": "1.0",
        "channelExtra": "{\"authCode\":\"280812820366966512\"}",
        "appId": this.payOrderInfo.appId,
        "notifyUrl": "https://www.jeequan.com",
        "signType": "MD5",
        "currency": "cny",
        "returnUrl": "",
        "mchNo": this.payOrderInfo.mchNo,
        "divisionMode": 0
    }).then((res) => {
        if (res.payData) {
          window.location.href = res.payData
        }
      })
    },


    /* 唤醒微信支付*/
    onBridgeReady() {

      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', JSON.parse(that.resData.payInfo),
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // //重定向
              if(that.payOrderInfo.returnUrl){
                location.href = that.payOrderInfo.returnUrl;
              }else{
                alert('支付成功！');
                window.WeixinJSBridge.call('closeWindow')
              }

            }
            if (res.err_msg == "get_brand_wcpay_request:cancel") {
              alert("支付取消");
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "get_brand_wcpay_request:fail") {
              alert('支付失败:' + JSON.stringify(res))
              window.WeixinJSBridge.call('closeWindow')
            }
            if (res.err_msg == "total_fee") {
              alert('缺少参数')
              window.WeixinJSBridge.call('closeWindow')
            }
          }
      );
    },

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>
